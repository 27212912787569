body {
    background: #333;
    max-width: calc(600px + 10%);
    margin: 0 auto;
    padding-left: 5%;
    padding-right: 5%;
}
.intro {
    height: 100vh;
}
.intro img {
    margin-bottom: 16px;
}
.intro p {
    color: white;
}
/* HEADER */
header {
    position: relative;
    height: 50px;
}
.head {
    position: fixed;
    width: 90%;
    max-width: 600px;
    background-color: #333;
    height: 50px;
    z-index: 10;
}
.head .head-nav {
    width: calc(100% - 60px);
}
.head .back {
    height: 50px;
    width: 60px;
}
.head .back svg {
    font-size: 32px;
}
.head .title {
    font-size: 24px;
    width: 100%;
    text-align: center;
    color: white;
}
.shopping-cart {
    margin-left: auto;
}
.shopping-cart button:not(:last-child) {
    margin-right: 16px;
}
.shopping-cart .cart-detail-link svg {
    font-size: 24px;
}
.shopping-cart .cart-detail-link .cart-article {
    color: black;
    font-size: 60%;
    margin-top: -16px;
    margin-left: 0px;
    font-weight: 700;
    background: white;
    border-radius: 50%;
    width: 12px;
    height: 12px;
}
.shopping-cart .cart-detail-link {
    height: 50px;
    width: 60px;
}
/* INTRO CTA PAGE */
.intro-wrapper {
    padding-top: 5%;
}
.cta-intro {
    padding: 5%;
}
.cta-intro .cta-icon {
    font-size: 120px;
    color: white;
}
.cta-intro .cta-text {
    width: 100%;
}
.comp {
    position: relative;
    border: 1px solid white;
}
.comp:not(:last-child) {
    margin-bottom: 65px;
}
.comp-title {
    color: white;
    position: absolute;
    top: -12px;
    left: 15px;
    background: #333;
    padding: 0 15px;
    display: flex;
    align-items: center;
}
.comp-title svg {
    margin-right: 15px;
}
/* GOURMET BOUTIQUE */
/* LIST */
.product-list {
    background-color: #333;
}
.product {
    margin: 8px;
    border: 1px solid white;
    width: 64px;
    height: 64px;
    color: white;
}
.product .product-number {
    width: 100%;
    height: 100%;
    background: #333;
    color: white;
    cursor: pointer;
}
.product-list .product-sale {
    position: absolute;
    width: 32px;
    top: 5px;
    left: 0px;
    transform: translate(-50%);
}
/* DETAIL */
.product-detail {
    background: white;
    padding-bottom: 5%;
}
.product-detail .wrapper {
    padding: 5%;
}
.product-detail img {
    width: auto;
    display: block;
    margin: 0 auto;
    max-height: 320px;
}
.product-detail .product-producer {
    padding-top: 8px;
}
.product-detail .product-image {
    padding: 24px 0;
}
.product-detail .product-desc {
    margin-bottom: 32px;
    overflow: hidden;
    max-height: 100%;
    transition: max-height .2s ease-in-out;
    -webkit-transition: max-height .2s ease-in-out;
    -moz-transition: max-height .2s ease-in-out;
    -ms-transition: max-height .2s ease-in-out;
    -o-transition: max-height .2s ease-in-out;
}
.product-detail .product-desc.short,
.short p {
    white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.product-detail .read-more {
    cursor: pointer;
    transform: translateY(-32px);
    -webkit-transform: translateY(-32px);
    -moz-transform: translateY(-32px);
    -ms-transform: translateY(-32px);
    -o-transform: translateY(-32px);
    text-decoration: underline;
}
.product-detail .price-box {
    position: relative;
    border: 1px solid #333;
    display: inline-block;
    padding: 8px 32px;
}
.product-detail .price-box .product-sale {
    position: absolute;
    width: 42px;
    left: 0;
    top: 10px;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
}
.count {
    width: 50px;;
}
/* CART */
.cart-detail {
    background-color: white;
    padding: 5%;
}
.cart-detail .product-image {
    display: block;
    margin-top: 8px;
    max-height: 80px;
}
.cart-detail .cart-product:not(:last-child) {
    border-bottom: 1px solid #333;
    margin-bottom: 24px;
    padding-bottom: 24px;
}
.cart-detail .total {
    border-top: 4px double #333;
    margin-top: 24px;
    padding: 8px 0px
}
.cart-detail .repeat-order {
    padding-bottom: 32px;
}
.cart-detail .repeat-order input {
    height: 22px;
    width: 22px;
    margin: 0px 12px;
}
/* CHECK OUT */
.check-out {
    background: white;
    padding: 5%;
}
.check-out .icon {
    font-size: 150px;
}
.apartments .apartment {
    margin: 10px 0px;
    border: 1px solid white;
    width: calc(50% - 10px);
    height: 110px;
    color: white;
}
/* SUMMARY */
.summary {
    padding-bottom: 24px;
}
.summary .summary-product:not(:last-child) {
    border-bottom: 1px solid #333;
    margin-bottom: 24px;
    padding-bottom: 24px;
}
.summary .summary-price {
    border-top: 4px double #333;
    padding-top: 16px;
}
/* FOOTER */
footer {
    position: relative;
    width: 100%;
    height: 50px;
}
footer .wrapper {
    position: fixed;
    bottom: 0;
    background: #333;
    max-width: 600px;
    height: 50px;
    color: white;
    width: 90%;
    z-index: 10;
}
.lang button {
    color: white;
    height: 50px;
}
.lang button:not(:last-child) {
    margin-right: 32px;
}
footer .homeIcon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    height: 50px;
    width: 60px;
}
footer .homeIcon svg {
    font-size: 26px;
}
footer .exitButton {
    color: white;
    height: 50px;
}
footer .exitButton svg {
    font-size: 20px;
    margin-left: 8px;
}
/* BACKEND */
.product-list.backend .product {
    margin: 0 0 15px 0;
    width: 100%;
    height: auto;
    border: none;
}
.product-list.backend .product.inactive * {
    color: firebrick;
}
.product-list.backend .numb {
    width: 30px;
    display: inline-block;
    text-align: right;
    margin-right: 5px;
}
.product-detail.backend form {
    margin-top: 20px;
}
.product-detail.backend form > * {
    width: 50%;
    display: inline-block;
    padding: 5px;
}
.product-detail.backend form label {
    font-weight: 700;
    text-align: right;
}
.product-detail.backend form .submit {
    margin-top: 40px;
}
.purchases-list {
    padding: 5%;
    background-color: white;
}
.purchases-list .purchase-filter {
    padding-bottom: 32px;
}
.purchases-list .purchase-filter button {
    margin: 5px;
}
.purchases-list .purchase {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 2px solid #333;
}
.purchases-list .purchase .order {
    font-weight: bold;
}
.stats button {
    margin: 5px;
}

/* Bread order */
/* LIST */
.bread-list {
    background-color: #333;
}
.bread-list .bread-categories,
.categories {
    background: #333;
    padding: 24px 0;
}
.bread-list .bread-categories .category,
.categories .category {
    margin: 1px;
}
.bread-list .bread {
    position: relative;
    margin: 5px;
    width: calc(33.33% - 10px);
    color: #333;
    background-color: white;
}
.bread-list .bread .bread-number {
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 4px;
}
.bread-list .bread .bread-name {
    font-size: calc(18rem / 24);
    hyphens: auto;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
}
.bread-list .bread .bread-price {
    padding: 8px 0px;
}
.bread-list .bread .bread-image-wrapper {
    height: 80px;
    margin: 8px 0;
}
.bread-list .bread .bread-image {
    max-height: 80px;
    max-width: 120px;
    width: 100%;
}
.bread-list .bread .counter {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.5);
    color: white;
    font-weight: bold;
}
.bread-list .bread .counter .delete-article {
    margin-top: auto;
}
.bread-wrapper {
    height: calc(100vh - 150px);
}
.bread-list .cancel-order {
    margin-top: 48px;
}

/* ------------------------------------------------------------------ */
/* -------------------------------- RENTAL --------------------------- */
/* ------------------------------------------------------------------ */
.rental-service {
    background: white;
    text-align: center;
}
.content,
.rental-list {
    padding: 5%;
}
.rental-image {
    max-width: 220px;
    margin: 24px 0;
}
.rental-service .rental-list .rental:not(:last-child) {
	border-bottom: 1px solid #333;
	padding-bottom: 32px;
	margin-bottom: 32px;
}
.price-box {
    position: relative;
    border: 1px solid #333;
    display: inline-block;
    padding: 8px 32px;
	margin-top: 16px;
}
.react-datepicker__day--excluded {
	background-color: red !important;
	color: white !important;
}
.react-datepicker .react-datepicker__day--highlighted.react-datepicker__day--disabled {
	background-color: unset;
}
.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
	width: 1.7rem !important;
	line-height: 1.7rem !important;
}
.react-datepicker__input-container {
	display: flex;
	cursor: pointer;
}
.react-datepicker__input-container input {
	text-align: center;
	cursor: pointer;
	color: white;
}
.react-datepicker__input-container input::placeholder {
	color: white;
	text-transform: uppercase;
	font-weight: 700;
}
.rental-service .selection {
	padding-top: 32px;
}
.rental-service .selection .selection-date {
	margin: 16px 8px;
}
.rental-service .datepicker .select-date {
	margin-left: 8px;
	margin-right: 8px;
}
.rental-service .selection .datepicker {
    display: flex;
}
.rental-service .selection .datepicker .select-date {
    margin: 16px 8px;
    display: flex;
    align-items: center;
}
.rental-list .read-more {
    text-decoration: underline;
}